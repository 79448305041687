@use '../config/' as *;
.waf-partner {
    @extend %mx-2-neg;
    @extend %page-head-blue-bg;
    &.waf-component {
        @extend %p-y-0-10;
        @extend %my-0;
    }
    .waf-head {
        padding: 11.12rem var(--space-4);
        &::before {
            top: 12rem;
        }
    }
    .waf-body {
        max-width: 100%;
        margin-inline: 0;
        margin-top: var(--space-20-neg);
        @extend %p-10-4;
    }
    .title {
        max-width: var(--container-max-width);
        @extend %title-30;
        @extend %neutral-0;
        @extend %mb-0;
    }
    .text {
        @extend %body-l;
    }
    .image {
        max-height: 4rem;
        object-fit: contain;
        @extend %mb-4;
    }
    .btn {
        &-text {
            @extend %font-0;
        }
        &-more {
            @extend %position-t-l;
            @extend %w-100;
            @extend %h-100;
        }
        &-close {
            width: 2.4rem;
            aspect-ratio: 1/1;
            right: 4.8rem;
            &::after {
                @extend %primary-50;
            }
        }
        &-link {
            @extend %d-block;
            @extend %text-center;
            @extend %mt-2;
            @extend %primary-50;
        }
    }
    .description {
        @extend %mt-8;
    }
    .client {
        &-title {
            @extend %title-40;
            @extend %mb-6;
        }
        &-logo {
            @extend %relative;
            @extend %border-radius-m;
            @extend %p-4;
            @include border(1, neutral-20);
            &:hover {
                border-color: var(--neutral-10);
                @extend %bg-neutral-5;
            }
            &:not(:last-of-type) {
                @extend %mb-4;
            }
        }
        &-list {
            &:not(:last-of-type) {
                @extend %mb-4;
            }
        }
        &-name {
            @extend %body-l;
            @extend %neutral-70;
            @extend %text-center;
        }
    }
    .modal {
        .client-logo {
            border: 0;
            @extend %p-0;
            &:hover {
                background-color: unset;
                border: 0;
            }
        }
        &-window {
            background: hsla(var(--hsl-neutral-100)/.7);
            @extend %d-none;
            &.active {
                @extend %flex;
            }
        }
        &-body {
            max-height: calc(100% - 8rem);
            max-width: 84.6rem;
            min-width: 90%;
            overflow-y: auto;
            padding-top: var(--space-22);
            @extend %my-10;
            @extend %mx-auto;
            @extend %p-4;
            @extend %border-radius-l;
            @extend %bg-neutral-0;
            @include custom-scroll();
        }
    }
}
@include mq(col-md) {
    .waf-partner {
        .modal {
            &-window {
                padding: 0;
            }
            &-body {
                max-height: calc(100% - 17.6rem);
                margin-block: var(--space-22);
                padding: 12.8rem 8rem 8rem 8rem;
            }
            .image {
                max-height: 5.6rem;
            }
        }
    }
}
@include mq(col-tablet) {
    .waf-partner {
        &.waf-component {
            padding-block: 0 var(--space-24);
        }
        .waf-body {
            padding: 8rem 4rem;
            max-width: var(--container-max-width);
            margin-inline: auto;
        }
        .waf-head {
            // padding-block: 11.12rem 2rem;?
            &::before {
                top: 50%;
            }
        }
        .title {
            font-size: 5.6rem;
            // transform: translateX(4rem);
            margin-left: 6rem;
        }
        .client {
            &-title {
                margin-bottom: var(--space-0);
                width: 29%;
            }
            &-list {
                display: flex;
                gap: var(--space-6);
                &.global-sponsor {
                    .image {
                        max-height: 8rem;
                    }
                    .client-item {
                        > * {
                            width: calc(100% / 2 - ((1) * var(--space-6) / 2));
                        }
                    }
                }
                &:not(:last-of-type) {
                    margin-bottom: var(--space-18);
                }
            }
            &-item {
                width: 69%;
                @include card-count(2, var(--space-6), wrap);
            }
            &-logo {
                min-height: 13.7rem;
                &:not(:last-of-type) {
                    margin-bottom: 0;
                }
            }
        }
    }
}
@include mq(col-lg) {
    .waf-partner {
        .btn-close {
            right: calc(((var(--window-inner-width) - 84.6rem) / 2) + 1.6rem);
        }
        .modal {
            &-body {
                min-width: 84.6rem;
            }
        }
        .client {
            &-item {
                > * {
                    width: calc(100% / 3 - ((2) * var(--space-6) / 3));
                }
            }
        }
    }
}
@include mq(col-xxl) {
    .waf-partner {
        .title {
            margin-left: 11rem;
        }
    }
}